<template>
    <ul class="news">
      <li v-for="(item,index) in news" :key="index" class="title" @click="go_news(item.id)">{{item.title}}</li>
    </ul>
  </template>
  
  <script>
  export default {
      data(){
          return{
              news:[]
          }
      },
      async created(){
    let res = await this.$axios("get_allNews",);
    this.news=res
      },
      methods:{
        go_news(id){
            this.$router.push({
          name: "News",
          query: {
            id: id,
          },
        });
        }
      }
  
  }
  </script>
  
  <style lang="less" scoped>
  .news{
      width: 50%;
      margin: 10px 25%;
      // border: 1px solid rgb(192, 188, 188);
      .title{
        width: 100%;
        height: 25px;
        margin: 5px 0;
        text-align: left;
      }
      .title:hover {
        cursor: pointer; /* 鼠标放上去变成小手 */
        color: rgb(230, 69, 69); /* 字体颜色变成红色 */
    }
  }
  </style>